.banner-suscription {
  background-color: #957FAD;
  padding: 70px 20px 0px;
}

.banner-suscription-content {
  max-width: 1150px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.banner-suscription .text {
  margin-top: 50px;
  width: 50%;
}

.banner-suscription .text h1 {
  width: 550px;
  margin-bottom: 0px;
}

.banner-suscription .text .caption {
  font-size: 30px !important;
}

.banner-suscription .text .caption span {
  color: #77EDEA;
  font-weight: 600;
}

.banner-suscription .img-side img {
  height: 660px;
}

.banner-suscription-content .img-side {
      width: 50%;
  margin-top: -30px;
  margin-bottom: 50px;

}





@media (max-width: 768px) {
  .banner-suscription {
    padding: 0px;
    padding-top: 40px;
  }

  .banner-suscription-content {
    max-width: 350px;
    margin: auto;
    padding: 0px 10px;
    text-align: center;
    flex-direction: column;
  }

  .banner-suscription .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: auto;
  }

  .banner-suscription .text h1 {
    width: 280px;
    margin-bottom: 20px;
  }

  .banner-suscription .text .caption {
    font-size: 17px !important;
    max-width: 200px;
  }

  .banner-suscription-content .img-side img {
    height: 320px;
  }

  .banner-suscription-content .img-side {
    margin: 0px auto 30px;
    width: 100%;
  }

}