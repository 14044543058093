.price-box {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 20px; */
  /* padding: 0px 20px; */
  /* background-color: #E1E9F5;
  border-radius: 10px; */
  /* box-shadow: 2px 3px 6px #5757572f; */
}

.price-box p {
  color: #151C45;
  font-size: 25px;
  font-weight: 500;
}

.price-box-child {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
  padding: 27px 25px;
}

.price-box-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-box-child .header-box-left {
  display: flex;
  align-items: center;
}

.price-box-child .header-box-left span {
  font-size: 22px;
}

.price-box-child .header-box-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  color: #151B46;
  width: 222px;
  margin: 30px 0px;
}

.price-box-child .header-box-right strong {
  font-size: 33px;
}

.price-box .icon-price-box {
  width: 50px;
  margin-right: 20px;
}

.price-box-child .header-box-right .note-price {
  color: #B2B2B2;
  font-size: 15px;
  margin-top: 5px;
}

.price-box-content {
  background-color: #F6F8FD;
  padding: 20px 17px;
  border-radius: 10px;
  margin-top: 15px;
}

.price-box-content img {
  margin-right: 15px;
  width: 25px;
  height: auto;
  margin-top: 11px;
}

.price-box-content>div {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px
}

.price-box-content>div span {
  font-size: 20px;
  color: #151C45;
}

.price-box-content .note-price {
  color: #7B7F98;
  font-size: 18px;
  line-height: 28px;
  margin-top: 5px;
  max-width: 380px;
}

@media (max-width: 768px) {
  .price-box {
    width: auto;
    padding: 0px 10px;
    margin: 0px;
  }

  .price-box-child {
    padding: 17px 10px;
    width: 92%;
  }

  .price-box-child .header-box-left span {
    font-size: 15px;
  }

  .price-box .icon-price-box {
    width: 35px;
    margin-right: 10px;

  }
  .price-box-header {
    flex-direction: row;
    justify-content: space-between;
}

  .price-box p {
    font-size: 19px;
  }

  .price-box-child .header-box-right {
    width: auto;
    font-size: 14px;
    margin: 0px;
  }

  .price-box-child .header-box-right strong {
    font-size: 17px;
  }

  .price-box-child .header-box-right .note-price {
    text-align: end;
  }

  .price-box-content {
    padding: 10px;
    margin-top: 20px;
    justify-content: flex-start !important;
  }

  .price-box-content>div span {
    font-size: 13px;
  }

  .price-box-content img {
    margin-right: 16px;
    width: 20px;
  }

  .price-box-content .note-price {
    font-size: 11px;
    max-width: 220px;
    line-height: 15px;
  }

  .price-box-child .header-box-right .note-price {
    font-size: 9px;
    min-width: 111px !important;
  }

}