.antihacker-security {
  padding: 100px 20px 0px;
  background-color: #F2FAF9;
}

.antihacker-security-content {
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.antihacker-security .img-side img {
  height: 600px;
  margin-bottom: -4px;
}

.antihacker-security .text {
  max-width: 590px;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
}

.antihacker-security .text span {
  font-style: italic !important;
}

.antihacker-security .text .description {
  max-width: 540px;
}

.antihacker-security .text img {
  width: 200px;
}

@media (max-width: 768px) {
  .antihacker-security {
    padding-top: 65px ;
  }

  .antihacker-security-content {
    flex-direction: column-reverse;
    max-width: 350px;
    text-align: center;
  }

  .antihacker-security .text {
    margin-top: 0px;
    max-width: 300px;
    margin: auto;
  }

  /* .antihacker-security .text .description {
    max-width: 300px;
  } */

  .antihacker-security .img-side img {
    height: 360px;
  }
}