.banner-faq {
  background-color: #FFF7CB;
  padding: 70px 20px 0px;
}

.banner-faq-content {
  max-width: 1100px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.banner-faq .text {
  margin-top: 50px;

}

.banner-faq .text h1 {
  margin-bottom: 0px;
  max-width: 400px;
}


.banner-faq .text .caption {
  font-size: 30px !important;
  max-width: 400px;
}

.banner-faq .text .caption span {
  color: #77EDEA;
  font-weight: 600;
}

.banner-faq .img-side img {
  height: 630px;
}

.banner-faq-content .img-side {
  margin-top: -30px;
  margin-bottom: -4px;
  width: 60%;
}

@media (max-width: 768px) {
  .banner-faq {
    padding: 0px;
    padding-top: 40px;
  }

  .banner-faq-content {
    max-width: 350px;
    margin: auto;
    padding: 0px 10px;
    text-align: center;
    flex-direction: column;
  }

  .banner-faq .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: auto;
  }

  .banner-faq .text h1 {
    width: 280px;
    margin-bottom: 20px;

  }
  .banner-faq .text h1 {
  }
  

  .banner-faq .text .caption {
    font-size: 17px !important;
    max-width: 250px;
  }

  .banner-faq-content .img-side img {
    height: 280px;
  }

  .banner-faq-content .img-side {
    margin: 0px auto -4px;
    width: 100%;
  }

}