.collapse_wrap {
  margin-bottom: 35px;
}

.collapse_box {
  background-color: #F5F7FA;
  width: 420px;
  height: 90px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #DDDDDD;
  display: flex;
  cursor: pointer;
}

.collapse_wrap .open_box {
  background-color: #FFFFFF;
  border-radius: 10px;
}

.main_box {
  margin-left: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main_box .description-subtitle {
  margin-bottom: 0px;
  font-weight: bold;
}


.main_box .description {
  max-width: 303px;
  margin: auto;
  margin-top: 17px;
}

.box_control {
  display: none;
}


@media (max-width: 768px) {
  .collapse_box .box {
    height: 90px;
    margin: 0px auto;
    cursor: auto;
    width: auto;
  }

  .collapse_box {
    max-width: 350px;
    width: auto;
    height: 90px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .collapse_box .faqs_panel {
    padding: 0px 10px;
  }

  .collapse_box .main_box {
    height: 90px;
    width: auto;
  }

  .open_box {
    height: auto;
  }

  .box_control {
    display: block;
    width: 20px;
    margin-right: 24px;
  }

  .collapse_box .open {
    max-width: 280px;
    margin: 0px auto;
  }

}