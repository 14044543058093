.blockchain {
  max-width: 1200px;
  margin: auto;
  padding: 100px 0;
}

.blockchain .blockchain-container {
  display: flex;
  justify-content: space-around;
}

.blockchain .blockchain-container .subtitle {
  /* margin-bottom: 0px; */
  max-width: 520px;
}

.blockchain .blockchain-container .english{
  max-width: 545px;
}

.blockchain .blockchain-container h3 {
  max-width: 585px;
}

.blockchain .blockchain-container .description {
  max-width: 530px;
}

.blockchain .blockchain-container .note {
  margin-top: 0px;
  max-width: 480px;
}

.blockchain .blockchain-container .left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blockchain .blockchain-container .right-side { 
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.blockchain-img {
  height: 650px;
}


.blockchain .blockchain-container .show-button {
  margin-top: 30px;
}


@media (max-width: 768px) {
  .blockchain {
    max-width: 350px;
    padding: 60px 15px;
    align-items: center;
    text-align: center;
  }

  .blockchain .blockchain-container .subtitle {
    max-width: 310px;
    margin: 0 auto;
  }

  .blockchain .blockchain-container {
    margin: auto;
  }

  .blockchain-img {
    /* width: 250px; */
    height: 450px;
    margin: auto;
    margin-bottom: 40px;
  }

  .blockchain .right-side {
    margin: auto !important;
    padding-top: 30px;
  }

  .blockchain .blockchain-container h3 {
    font-size: 20px;
    max-width: 320px;
    margin: 40px auto;

  }

  .blockchain .blockchain-container .description {
    margin-top: 40px;
    max-width: 300px;
    margin: 0 auto;
  }

  .blockchain .blockchain-container .note {
    /* margin-top: 50px; */
    max-width: 300px;
    margin: 30px auto 0px;
  }

  .blockchain .left-side {
    align-items: center;
  }

}