.organize {
    background-color: #F0F5FA;
    padding: 100px 20px 150px;
}

.organize-content {
    max-width: 1110px;
    margin: auto;
}

.organize-content .description b {
    font-style: italic;
}

.organize-row {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.organize-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.organize .screen-container {
    position: relative;
    height: 700px;
    width: 330px;
    margin-top: 23px;
}

.organize-text .subtitle {
    margin-bottom: 30px;
}

.organize-text .description {
    margin: 0px;
    max-width: 670px;
}

.organize .left-row {
    display: flex;
    align-items: center;
}


.organize .screen {
    height: 680px;
    margin-left: 60px;
}

.showed {
    display: block !important;
}


.crown_screen
{
    width: 150px;
    position: absolute;
    margin-left: 35px;
    margin-top: -73px;
}

@media (max-width: 768px) {
    .organize {
        min-height: auto;
        padding: 60px 0px;

    }

    .organize .organize-content {
        max-width: 350px;
        padding: 0 20px;
        height: auto;
        margin-top: 0px;
    }

    .organize-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }

    .organize  .cards-container {
        margin-top: 30px;
    }

    .organize-text {
        max-width: 350px;
        padding: 0px 10px;
    }

    .organize .left-row {
        min-height: 600px;
        display: block;
        text-align: center;
        position: relative;
    }

    .organize .screen {
        width: 279px;
        height: auto;
        margin: 60px auto 0px;
    }
    .screen-container {
        display: contents;
    }

    .crown_screen
    {
        width: 120px;
        position: absolute;
        margin-left: -20px;
        margin-top: 0px;
    }

    .screen-container {
        position: relative;
    }

    .organize .description {
        margin-bottom: 0px;
        max-width: 370px;
    }

}