.about-info .subtitle img {
  width: 100px;
  margin: 0 15px;
}

.about-info .about-container {
  display: flex;
  justify-content: space-between;
}

.about-info .about-container .left {
  background-color: #AC91CA;
  width: 560px;
  padding-top: 130px;
  display: flex;
  align-items: flex-end;
}

.about-info .about-container .left img {
  width: 700px;
}

.about-info .about-container .right {
  width: 50%;
  margin-left: 150px;
  padding-right: 50px;
}

.about-container .right-text {
  max-width: 508px;
  padding-top: 70px;
}

@media (min-width: 1480px) {
  .about-info .about-container .left {
    width: 50%;
  }

  .about-info .about-container .left img {
    margin-left: 170px;
  }
}

@media (max-width: 768px) {
  .about-container .right-text {
    padding-top: 0px;
  }

  .about-info .about-container .right {
    width: 285px;
    margin: 0px auto;
    padding: 60px 20px 30px;
  }

}