.menu-bar {
    box-shadow: 1px 0px 6px #8098af;
    overflow: hidden;
    height: 90px;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.menu-bar .menu-bar-content {
    height: 100%;
    margin: 0px;
    max-width: 1300px;
    align-items: center;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
}

.menu-bar .menu-bar-content .logo-container img {
    height: 50px;
    margin-right: auto;
    cursor: pointer;
}

.menu-bar .nav-links-container .menu-item {
    height: 40px !important;
    cursor: pointer;
    align-items: center;
    display: flex;
    color: #031649;
    text-decoration: none;
    border-radius: 27px;
    padding: 0px 20px;
}

.menu-bar .active {
    background-color: #F0F0F0;
}

.menu-bar .nav-links-container span {
    margin-left: 5px;

}

.button-language img {
    width: 17px;
    margin-right: 8px;
}


.button-language {
    align-self: center;
    width: 90px;
    height: 30px;
    border: none;
    border-radius: 15px;
    background-color: #0084C0;
    padding: 0px 5px;
    cursor: pointer;
    margin-left: 10px;
}

.button-language span {
    color: #FFFFFF;
    font-size: 17px;
    margin-left: 5px;
    width: 22px;

}


.plans .header-row .region {
    margin-right: 30px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    background-color: #F6F8FD;
    height: 45px;
    width: 150px;
    border-radius: 5px;
    cursor: pointer;

}

.plans .header-row .region img {
    width: 45px;
    margin-right: 15px;
}

.popper-language .language-row {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    /* border-bottom: 0.5px solid #5757572f; */
    cursor: pointer;
    background-color: #F6F8FD;
    border-radius: 10px;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 10px !important;
}

#language-popover {
    margin-top: 5px;
}


.popper-language {
    background-color: #FFFFFF;
    box-shadow: 2px 3px 6px #5757572f;
    border-radius: 10px;
    padding: 10px 10px 1px;
    width: 200px;
}

.popper-language .language-row {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #151C45;
}



.language-row .check {
    height: 13px;
    margin-right: 15px;
    filter: brightness(1.5) grayscale(1);
}

.language-row.active .check {
    filter: none;
}

.popper-language .language-row.active {
    background-color: #E1E9F5;
}


.language-row .css-cveggr-MuiListItemIcon-root {
    min-width: 20px;
    margin-left: 15px;
}

.side_menu_list  .language-row .check {
    margin-right: 10px;
}




@media (max-width: 768px) {
    .menu-bar {
        width: 100%;
        height: 75px;
    }

    .menu-bar .menu-bar-content {
        padding: 0px;
    }

    .menu-bar .menu-bar-content .logo-container img {
        height: 36px;
        margin-left: 24px;
    }

    .menu_button .menu_icon {
        width: 28px;
        height: 18px;
        margin-right: 24px;
    }

    .MuiDrawer-paper {
        border-top-left-radius: 20px;
        margin-top: 10px;
    }

    .side_menu_title {
        margin-left: 47px;
    }

    .side_menu_list {
        margin: 0 28.4px !important;
    }

    .css-1f8bwsm {
        min-width: 35px  !important;
        margin-left: 15px !important;
    }
}