.banner-security {
  background-color: #FF597C;
  padding: 70px 20px 0px;
}

.banner-security-content {
  max-width: 1150px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.banner-security .text {
  margin-top: 50px;
}

.banner-security .text h1 {
  width: 650px;
  margin-bottom: 0px;
}

.banner-security-content .right-side {
  margin-top: -30px;
  margin-left: -200px;
  margin-bottom: -4px;
}

.banner-security-content .security-man {
  height: 660px;
}

.banner-security-content .white {
  color: #FFFFFF !important;
  font-weight: normal !important;
  font-size: 20px;
  max-width: 450px !important;
  margin-top: 20px;
}


@media (max-width: 768px) {
  .banner-security {
    padding: 0px;
    padding-top: 40px;
  }

  .banner-security-content {
    max-width: 350px;
    margin: auto;
    padding: 0px 10px;
    text-align: center;
    flex-direction: column;
  }

  .banner-security .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .banner-security .text h1 {
    width: 300px;
  }

  .banner-security-content .white {
    max-width: 260px !important;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .banner-security-content .security-man {
    height: 320px;
  }

  .banner-security-content .right-side {
    margin: 0px auto -4px;
  }




}