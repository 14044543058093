.about-us {
  padding: 70px 20px 0px;
  background-color: #E8F8DE;
}

.about-us-content {
  max-width: 1100px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.about-us .text {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.about-us .text .heading {
    margin-bottom: 0px;
}

.about-us .text .captions {
  margin-top: 0px;

}

.about-us-content .back {
  height: 660px;
}

.about-us .message {
  position: relative;
}

.about-us .message img {
  width: 270px;
  height: auto;
  position: absolute;
  right: -120px;
  margin-top: 30px;
}


@media (max-width: 768px) {
  .about-us {
    height: auto;
    padding: 0px;
    padding-top: 40px;
  }

  .about-us-content {
    max-width: 350px;
    padding: 0px 20px;
    flex-direction: column;
  }

  .about-us .text {
    max-width: 250px;
    text-align: center;
    margin: 0px auto;
  }

  .about-us .text .heading {
    margin-bottom: 20px;
}

  .about-us-content .back{
    margin-top: 10px;
    max-height: 400px;
    height: auto;
  }

}