.create-tiim {
    height: 900px;
    padding: 60px 0px 0px;
}

.create-container {
    max-width: 1100px;
    margin: auto;
    height: 100%;
}

.create-tiim .left-content {
    height: 100%;
}

.create-tiim .left-content h2 {
    max-width: 400px;
    margin-top: 0px;
}

.create-tiim .left-content h3 {
    color: #03144A;
    margin-top: 0px;
}

.create-tiim .left-content p {
    max-width: 500px;
}

.create-tiim .left-content .note {
    max-width: 450px;
}
.subtitle span{
    margin-right: 10px;
}

.create-tiim .left-content .subtitle img {
    width: 100px;
    margin-right: 15px;
}

.create-tiim .create-right-content div {
    position: relative;
    margin-top: 80px;
}

.create-tiim .create-right-content img {
    position: absolute;
    margin-left: -160px;
    max-height: 701px;
}

.create-tiim .create-right-content .createScreen{
    margin-left: -100px;
}

.create-tiim .button-container {
    margin-top: 30px;
    margin-bottom: 50px;
}

@media (max-width: 768px) {

    .create-tiim {
        height: auto;
        padding: 70px 0px ;
    }

    .create-tiim .create-container {
        max-width: 350px;
        padding: 0px 20px;
    }

    .create-tiim .left-content {
        height: auto;
        text-align: center;
    }

    .subtitle span{
        margin-right: 10px;
    }

    .create-tiim .left-content .subtitle img {
        width: 70px;
        margin-right: 10px;
    }

    .create-tiim .left-content h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .create-tiim .left-content .mobile-screen {
        position: relative;
        max-width: 280px;
        margin: 20px auto 30px;   
    }

    .create-tiim .button-container {
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .create-tiim .left-content .note {
        max-width: 295px;
        margin: auto;
    }
    
}