.download-bar {
  height: 120px;
  background-color: #ADF7D6;
}

.download-bar p {
  font-weight: bold;
  margin: 0;
  margin-right: 30px;
  font-size: 25px;
}

.download-bar .free-text {
  color: #D18100;
}

.download-bar img {
  height: 52px;
  width: 150px;
  cursor: pointer;
  margin-right: 15px;
}

@media (max-width: 768px) {
  
  .download-bar {
    min-height: 150px;
    padding: 15px 0px;
  }
  .download-bar p {
    font-size: 17px !important;
    text-align: center;
    margin: auto;
    padding: 0 20px;
  }

  .download-bar span {
    font-size: 22px;
    line-height:2;
  }

  .download-bar img {
    height: 40px;
    width: 130px;
    margin: 0 5px;
  }
  
}