.faq-suscription {
  padding: 100px 20px;
  background-color: #FFF;
}

.faq-suscription-content {
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.faq-suscription .img-side img {
  height: 850px;
}

.faq-suscription .text {
  max-width: 590px;
  display: flex;
  flex-direction: column;
}

.faq-suscription .text .description {
  max-width: 540px;
}
.faq-suscription .text .show-button {
  align-self: center;
}


@media (max-width: 768px) {
  .faq-suscription {
    padding: 65px 20px;
  }

  .faq-suscription-content {
    flex-direction: column-reverse;
    max-width: 350px;
    text-align: center;
  }

  .faq-suscription .text {
    margin-top: 0px;
    max-width: 300px;
    margin: auto;
    text-align: left;
  }


  .faq-suscription .img-side img {
    height: 360px;
  }

  .faq-suscription .show-button{
    margin: auto;
  }

}