.border_box {
    display: flex;
    flex-direction: column;
    border-bottom: .5px solid #BEBEBE;
    padding: 10px 15px;
}

.border_box_title {
    font-size: 25px;
    font-weight: 500;
    max-width: 540px;
    color: #03144A;
}

.border_box_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.border_box .border_box_text {
    width: 480px;
    display: none;
    margin-bottom: 0px;
}

.border_box .border_box_text p {
    margin-top: 0px;
}

.border_box .box_control {
    display: flex;
    height: 12px;
}

.border_box .open_border_box {
    display: block;
}

/* @media (max-width: 768px) {
 
    .border_box {
        padding: 10px;
        padding-right: 9px;
    }

    .border_box .box_control {
        height: 25px;
        margin-right: 0px;
        margin-left: 5px;
    }

    .border_box .border_box_text {
        width: 280px;
        margin-bottom: 20px;
    }

    .border_box .border_box_text p {
        margin-top: 10px;
        font-size: 16px;
    }


} */