.data-security {
  padding: 120px 20px 110px;
}

.data-security-content {
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.data-security .text {
  max-width: 550px;
}

.data-security .text .description-subtitle {
  width: 500px;
}

.data-security .text .subtitle,
.data-security .text .description {
  max-width: 490px;
}

.data-security .text .description span {
  font-style: italic;
}

.data-security-content .img-side img {
  max-height: 900px;
  margin-top: -40px;
}

.data-security .text button {
  margin-left: 100px;
  margin-top: 30px;
}


@media (max-width: 768px) {
  .data-security {
    padding: 65px 20px 30px;
  }

  .data-security-content {
    max-width: 350px;
  }

  .data-security .text {
    max-width: 285px;
    margin: auto;
  }

  .data-security .text .description-subtitle {
    max-width: 285px;
  }
}