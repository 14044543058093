.about-security {
  background-color: #F2FAF9;
  padding: 80px 20px;
}

.about-security .content {
  background-color: #E4F3F1;
  box-shadow: 0 3px 6px #ddd;
  border-radius: 10px;
  width: 90%;
  padding: 70px 20px;
  margin: 0 auto;
}

.about-security .content .text {
  max-width: 960px;
  margin: 0px auto;
}

.about-security .content .description { 
  color: #03124B;
}

.about-security .content .text div  {
  display: flex;
  align-items: center;
}
.about-security .about-shield {
  width: 55px;
  height: auto;
  margin-right: 15px;
}

.about-security .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.about-security .text .note {
  margin: auto 0;
}

@media (max-width: 768px) {
  .about-security {
    padding: 36px 20px 60px;
  }

  .about-security .content .text {
    max-width: 250px;
    margin: 0px auto;
    text-align: center;
  }

  .about-security .content {
    padding: 40px 20px;
    width: 80%;
  }
  .about-security .about-shield {
    margin: 0 auto;
  }
  .about-security .description-subtitle {
    font-size: 20px;
    margin-top: 27px;
  }

  .about-security .description {
    font-size: 19px;
    max-width: 235px;
    margin: 30px auto 0;
    line-height: 34px;
  }

  .about-security .note {
    max-width: 300px;
    text-align: center;
    margin: 30px auto;
  }


}