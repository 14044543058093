
.faq-question {
    background-color: #F2FAF9;
    padding: 70px 20px ;
}

.faq-question-content {
    max-width: 1150px;
    margin: auto;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
}


.faq-question .left {
    margin-top: 25px;
}

.faq-question .right {
    width: 640px;
}


.faq-question .no_visible {
    display: none;
}

.faq-question .collapse_box {
    background-color: #F6F9FB;
}

.faq-question .collapse_wrap .open_box {
    background-color: #FFFFFF;
}

.faq-question .collapse_wrap .collapse_icon {
    background-color: #D6E3E8
}

.faq-question .collapse_wrap .open_box .collapse_icon {
    background-color: #F4F5F7
}

@media (max-width: 768px) {

    .faq-question {
        padding: 0px;
        padding-top: 40px;
    }

    .faq-question-content {
        max-width: 350px;
        margin: auto;
        padding: 0px 20px;
        flex-direction: column;
    }

    .faq-question .left {
        margin-top: 0px;
    }

    .faq-question .border_box_title {
        font-size: 17px;
        text-align: left;
        line-height: 22px;
        max-width: 260px;
    }


   


}