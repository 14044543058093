.opinions {
    background-color: #F4F2FA;
    padding: 150px 20px 150px;
}
.opinions-container {
    max-width: 1200px;
    margin: auto;
}

.opinions .container-row {
    margin-top: 110px;
    display: flex;
    justify-content: space-between;
}

.opinions h1 {
    max-width: 617px;
    text-align: center;
    margin: auto;
}

.opinions h1.english{
    max-width: 500px;
} 


@media (max-width: 768px) {
    .opinions {
        padding-bottom: 0px;
        padding-top: 80px;
    }

    .opinions .container-row {
        flex-direction: column;
        width: 307px;
        margin: auto;
        margin-top: 60px;
    }
}