 .profile {
     padding: 100px 20px 150px;
 }

 .profile .profile-container {
     max-width: 1100px;
     margin: auto;
     padding: 0 20px;
     display: flex;
     justify-content: space-between;
 }

 .profile .profile-screen {
     width: 350px;
     margin-left: 60px;
 }

 .profile .text-profile {
     max-width: 50%;
     flex-direction: column;
     display: flex;
     justify-content: center;
 }


 .profile .profile-container .note {
    max-width: 410px;
 }

@media (max-width: 768px) {
    .profile {
        padding: 40px 20px;
    }

    .profile .profile-container {
        max-width: 280px;
        padding: 0px;
        flex-direction: column-reverse;
    }
    .profile .text-profile {
        max-width: 100%;
        text-align: center;
    }
    .profile .profile-screen {
        width: 280px;
        margin: 10px auto 40px;
    }

    .profile .description-subtitle
    {
        max-width: 250px;
        margin: 0px auto 30px;
    }
}