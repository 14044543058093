.bills {
    padding: 150px 20px;
    background-color: #F0F5FA;
}

.bills .bills-container {
    max-width: 1100px;
    margin: auto;
    padding: 0px 20px;
}

.bills .left-container {
    height: 100%;
}

.bills .left-container img {
    max-width: 350px;
    margin-left: 60px;
}

.bills .right-container {
    display: flex;
}

.bills .right-container .note {
    width: 500px;
}

.bills .right-container .description {
    max-width: 500px;
    margin-top: 0px;
}

.bills .bills-container button {
    margin-top: 40px;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .bills {
        padding: 70px 0px;
    }

    .bills .bills-container {
        max-width: 350px;
        padding: 0px 20px;
        text-align: center;

    }

    .bills .right-container .description-subtitle {
        margin-top: 0px;
        max-width: 300px;
    } 

    .bills .right-container .note {
        max-width: 290px;
        margin: 0px auto 30px;
    }

    .bills-mobile {
        max-width: 280px;
        margin: 10px auto 40px;
    }

    .bills .bills-container button {
        margin: 20px auto 0px;
    }
}