.plans {
  background-color: #F2FAF9;
  padding: 0px 20px 80px;
}

.plans-content {
  max-width: 1200px;
  margin: auto;
}

.plans-content>div {
  position: relative;
  top: -54px;
  max-width: 1130px;
  margin: auto;
  background-color: #E1E9F5;
  border-radius: 10px;
  box-shadow: 2px 3px 6px #5757572f;
  padding: 20px 0px;
}

.plans-content .note {
  text-align: end;
  font-style: normal;
  font-size: 17px;
  color: #5C88A8;
  margin-top: -35px;
  margin-right: 15px;
  max-width: 1130px;
}

.plans-row {
  display: flex;
  justify-content: space-evenly;
}

.plans .header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.plans .header-row .region {
  margin-right: 30px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background-color: #F6F8FD;
  height: 45px;
  width: 150px;
  border-radius: 5px;
  cursor: pointer;
}

.plans .header-row .region img {
  width: 45px;
  margin-right: 15px;
}

#region-popover {
  margin-top: 5px;
}


.popper-region {

  background-color: #FFFFFF;
  box-shadow: 2px 3px 6px #5757572f;
  border-radius: 10px;
  padding: 10px 10px 1px;
  width: 170px;
}

.popper-region .region-flag {
  width: 30px;
  margin-right: 8px;
}

.popper-region .region-row {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: .5px solid #5757572f;
  cursor: pointer;
}


.popper-region .region-row .check {
  height: 13px;
  margin-right: 15px;
  filter: brightness(1.5) grayscale(1);
}

.region-row.active .check {
  filter: none;
}


.plans-content .switch-button {
  background-color: #EDF3FB;
  width: 700px;
  border-radius: 75px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 40px 20px;
}

.plans-content .switch-option {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 37px;
  justify-content: center;
  font-size: 25px;
  cursor: pointer;
  color: #A8AEC1;
}

.plans-content .switch-option:hover {}


.plans-content .switch-option .label {
  margin-left: 15px;
  background-color: #A8AEC1;
  border-radius: 3px;
  padding: 8px 15px;
  color: #FFFFFF;
  font-size: 18px;

}

.plans-content .switch-button .active {
  background-color: #B8C9DE;
  color: #03144A;
}

.plans-content .switch-button .active .label {
  background-color: #3D81AB;
}

.plans-content-list {
  max-width: 1100px;
  margin: auto;
}

.plans-content-list span {
  color: #7B7F98;
  line-height: 35px;
  font-size: 21px;
}

.plans-content-list .icon_list {
  /* max-width: 840px; */
}

.plans-content-list .icon-list-item {
  margin-bottom: 25px;
  align-items: flex-start;

}

.plans-content-list .icon-list-item>img {
  margin-top: 8px;
}



@media (max-width: 768px) {
  .plans {
    padding: 0px;
    padding-bottom: 50px;
  }

  .plans-content {
    max-width: 100%;
  }

  .plans-row {
    flex-direction: column;
  }

  .plans-content>div {
    top: -34px;
    max-width: 330px;
  }

  .plans-content-list {
    margin: auto;
    padding: 0px 20px;
  }

  .plans-content-list .icon_list {
    max-width: 300px;
    margin: auto;
  }

  .icon-list-item {
    max-width: 300px;
    margin-bottom: 15px;
    align-items: flex-start;
  }

  .plans-content-list span {
    font-size: 12px;
    line-height: 20px;
  }

  .plans-content-list .icon-list-item>img {
    height: 15px;
    margin-top: 8px;
  }

  .plans-content .switch-button {
    width: 92%;
    margin: 0px 10px;
    height: 40px;
  }

  .plans-content .switch-option {
    font-size: 17px;
  }

  .plans-content .switch-option .label {
    font-size: 8px;
    padding: 5px;
    margin-left: 7px;
  }

  .plans-content .note {
    font-size: 10px;
    max-width: 330px;
    margin-top: -25px;
  }

  .plans .header-row {
    margin-bottom:  15px;
    padding-right: 10px;
  }

  .plans .header-row .region {
    margin-right: 5px;
    padding: 0px;
    background-color: transparent;
    width: 40px;
    height: auto;
  }

  .plans .header-row .region span {
    display: none !important;
  }

  .plans .header-row .region img {
    width: 35px;
    margin-right: 10px;
    margin-left: 5px;
  }

  .popper-region .region-row {
    font-size: 12px;
  }

  .popper-region {
    border-radius: 10px;
    padding: 10px;
    width: 130px;
}

}