.card {
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 3px 6px #ddd;
    margin-top: 25px;
    cursor: pointer;
    max-width: 550px;
}

.carbox-content {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.card .carbox-content .item-img {
    height: 60px;
}

.selected {
    background-color: #FFFFFF;
}

.card .card-text {
    max-width: 415px;
    margin-left: 20px;
    padding-top: 16px;
}

.card .card-text h4 {
    font-size: 18px;
    color: #151B46;
    margin: 0px;
}

.card-description {
    font-size: 18px;
    color: #696871;
    margin-top: 10px;
    padding-right: 25px;
    line-height: 25px;
}

@media (max-width: 768px) {
    .card {
        max-width: 350px;
        flex-wrap: nowrap !important;
        padding: 0px 10px 0px 25px !important;
        background-color: #FFFFFF;
        height: 90px;
        flex-direction: column !important;
    } 

    .card .carbox-content {
        width: 100%;
        height: 90px;
        padding-left: 0px;
    }

   .selected {
        min-height: 100px;
        height: auto;
    }


    .card-description {
        display: none !important;
    }

    .selected > .card-description {
        display: block !important;
        margin-top: 0px;
        margin-bottom: 25px;
        width: 260px;
        padding-right: 0px;
        font-size: 17px;
    }

    .card .carbox-content .item-img {
        height: 50px;
    }

    .card .card-text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 10px 0;
    }

    .card .card-text img {
        height: 10px;
        margin-right: 10px
    }

    .card .card-text h4 {
        font-size: 20px;
    }
}