.legal-content {
    padding: 70px 20px;
    background-color: #F2FAF9;
}

.legal-parent {
    max-width: 1200px;
    margin: auto;
}

.legal-content .update {
    color: #989898;
    font-size: 18px;
    text-align: right;
    padding-bottom: 20px;
}

.conditions-content {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 3px 6px #ddd;
    padding: 60px;
}

.text-container {
    max-width: 1000px;
    margin: auto;
}

.text-container h1 {
    font-size: 50px;
    color: #03144A;
    margin-bottom: 60px;
}

.text-container p,
.text-container li {
    font-size: 23px;
    color: #03144A;
    line-height: 38px;
}


@media (max-width: 768px) {
    .legal-content{
        padding: 50px 20px;
    }

    /* .legal-parent {
        max-width: auto;
        margin: auto;
    } */
    
    .conditions-content {
        height: 850px;
        overflow-y: scroll;
        text-align: justify;
    }

    .text-container h1 {
        font-size: 30px;
        margin-bottom: 35px;
    }

    .text-container p,
    .text-container li {
        font-size: 17px;
        line-height: 30px;
    }

    .conditions-content {
        padding: 20px;
    }

}