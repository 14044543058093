.icon-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon-list-item>span {
    margin-left: 13px;
    color: #151C45;
    font-size: 16px;
}

.icon-list-item>img {
    height: 20px;
}

.icon_list {
    display: flex;
    flex-direction: column;
}


@media (max-width: 768px) {

    .events .events-container .left-container .icon_list {
        margin: 20px auto;
    } 

    .icon-list-item {
        max-width: 260px;
    }
}