.events {
    padding: 150px 20px;
}

.events-content {
    margin-top: 30px;
    height: 840px;
}

.events .events-container {
    max-width: 1150px;
    margin: auto;
    padding: 0 20px;
}

.events .events-container .left-container {
    display: flex;
}

.events .events-container .left-container .description-subtitle {
    width: 540px;
}
.events .events-container .left-container .description {
    max-width: 450px;
}

.events .events-container .left-container  .icon_list{
    margin-left: 20px;
    margin-top: 20px;
}

.events .right-container img {
    max-width: 350px;
    margin-right: 60px;
}

@media (max-width: 768px) {
    .events {
        padding: 70px 18px;
    }

    .events-content {
        margin-top: 20px;
        height: auto;
    }

    .events .events-container {
        max-width: 350px;
        padding: 0px;
        text-align: center;
    }

    .events .events-container .left-container .description-subtitle {
        width: 300px;
        margin: auto;
    }

    .events .events-container .left-container .description {
        max-width: 280px;
        margin: 0 auto 40px;
    }

    .events .calendar-screen {
        max-width: 280px;
        margin: 30px auto 40px;
    }
}