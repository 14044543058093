.opinion_card {
    width: 350px;
    height: 210px;
    background-color: #FFFFFF;
    padding: 40px;
    margin: 0px 10px;
    border-radius: 14px;
    box-shadow: 0 3px 6px #ddd;

}

.opinion_header {
    display: flex;
}

.opinion_header>img {
    width: 56px;
    height: 56px;
    margin-right: 10px;
}

.opinion_header>div {
    display: flex;
    flex-direction: column;
}

/* .opinion {
    width: 300px;
    margin: auto;
    padding-top: 35px;
} */

.person .card-title {
    font-size: 20px;
    color: #03144A;
    font-weight: bold;
    margin-bottom: 7px;
}



.opinion_card .card-subtitle {
    font-size: 16px;
    color: #696871;
}

.opinion_card>.description>span {
    line-height: 39px;
    letter-spacing: -0.65646px;
    color: #696871;
}

.opinion_card>.description {
    max-width: 290px;
    margin-top: 20px;
}


@media (max-width: 768px) {
    .opinion_card {
        margin-bottom: 40px;
        width: 270px;
        padding: 20px;
        margin: 0 auto 40px;
    }

    .opinion_card>.description {
        max-width: 250px;
    }

    .opinion_card>.description>span {
        line-height: 29px;
    }
    .opinion_card>.description {
        max-width: 240px;
        margin: 20px auto 30px;
    }

    .opinion_header {
        margin-top: 20px;
    }
    
    

}