.banner {
  height: 940px;
}

.banner-bg {
  height: 100%;
  background: url(../../assets/images/banner.webp) no-repeat bottom;
  background-size: cover;
}

.banner .text-banner {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
}

.banner .text-banner .heading.english {
  max-width: 850px;
}

.banner .banner-heading {
  font-size: 70px;
  margin-bottom: 0px;
  max-width: 1000px;
  font-weight: 900;
}
.banner .caption.english {
  max-width: 850px;
}

.banner .caption {
  max-width: 750px;
}

.banner .descriptionEn {
  max-width: 1000px;
}

.banner .message {
  position: relative;
  margin-top: -60px;
}

.banner .message img {
  width: 270px;
  position: absolute;
  right: 13%;
  margin-top: -10px;
}

.banner .watch-video-container {
  display: flex;
  align-items: center;
}

.banner .watch-video-container p {
  color: #151b46;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
}

.banner .watch-video-container img {
  cursor: pointer;
  height: 70px;
  margin-right: 10px;
}


@media (min-width: 1480px) {
  .banner {
    min-height: 1100px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: auto;
  }

  .banner-bg {
    /* height: 80%; */
    height: 490px;
  }

  .banner .banner-heading {
    max-width: 325px;
    margin: auto;
    font-size: 35px;
    padding: 20px 15px 10px;
  }



  .banner .banner-caption {
    font-size: 17px;
    max-width: 370px;
    margin: 20px auto;
    text-align: center;
    line-height: 26px;
    padding: 0px 10px;
  }

  .banner .message img {
    width: 170px;
  }

  .banner .watch-video-container p {
    font-size: 16px;
  }

  .banner .watch-video-container img {
    height: 60px;
    margin-right: 0px;
  }


}